<template>
  <div class="login_content  fadeIn fourth">
    <warning-sample></warning-sample>

    <div class="login_panel">
      <div class="crt_panel">
        <img src="../../assets/images/logo/ptn/logo01.png" alt="" style="height: 70px">
      </div>

      <div class="login_items login_items_rel" v-if="$store.state.isMaintaining == false">
        <div class="ipt">
          <input type="text" v-model="user.username" placeholder="아이디" @keyup.enter="doLogin"/>
        </div>
        <div class="ipt">
          <input type="password" v-model="user.passwd" placeholder="비밀번호" @keyup.enter="doLogin">
        </div>
        <!--                <div class="ipt">-->
        <!--                    <input type="text" v-model="user.hvcode" placeholder="로봇방지코드" @keyup.enter="doLogin">-->
        <!--                    <img :src="imageSrc" alt="" style="width: 128px;margin-left: 5px" @click="refreshHVcode">-->
        <!--                </div>-->
        <div class="btns">
          <router-link tag="button" to="" @click.native="doLogin" class="login"
                       :class="{'samplerver':$store.state.sampleserver==1}">로그인
          </router-link>
          <router-link class="register" tag="button" to="/register" v-if="$store.state.sampleserver==0">회원가입
          </router-link>
        </div>

        <!--                <div class="btns">-->
        <!--                    <router-link tag="button" to="" @click.native="showNotMemberCustomerPanel" class="nomemberask">비회원문의&답변보기</router-link>-->
        <!--                </div>-->
        <div class="err" v-show="errmessage != null">
          <i class="fa fa-warning"></i> {{ errmessage }}
        </div>
      </div>
      <div class="login_items login_items_rel" v-if="$store.state.isMaintaining == true">
        <div v-html="$store.state.maintainingMessage">

        </div>
      </div>

      <div class="login_items" style="text-align: center;margin-top: 15px" v-if="$store.state.isMaintaining != true">
        <a href="#" @click="showNotMemberCustomerPanel" style="margin-top: 5px">
          <img alt="" src="../../assets/images/banner/ptn/notloginask.jpg" style="width: 80%">
        </a>
      </div>
    </div>


    <div class="p1 popup_section"
         v-if="popup1!=null && popup1.isShow">

      <div class="pop_box">
        <div class="event_img">
          <a :href="popup1.httplink" target="_blank">
            <img :src="popup1.imageUrl" alt="" style="width: 100%;">
          </a>
        </div>
      </div>
      <div class="pfoot" style="">
        <div @click="close24(popup1)" style="text-align: left;cursor: pointer">
          <i class="fa fa-square"></i> 오늘하루 그만보기
        </div>
        <div style="text-align: right;cursor: pointer">
          <button class="btn-close" @click="close(popup1)">닫기</button>
        </div>
      </div>

    </div>
    <div class="p2 popup_section"
         v-if="popup2!=null && popup2.isShow">

      <div class="pop_box">
        <div class="event_img">
          <a :href="popup2.httplink" target="_blank">
            <img :src="popup2.imageUrl" alt="" style="width: 100%;">
          </a>
        </div>
      </div>
      <div class="pfoot" style="">
        <div @click="close24(popup2)" style="text-align: left;cursor: pointer">
          <i class="fa fa-square"></i> 오늘하루 그만보기
        </div>
        <div style="text-align: right;cursor: pointer">
          <button class="btn-close" @click="close(popup2)">닫기</button>
        </div>
      </div>

    </div>


  </div>


</template>

<script>
import {getPopupList, getSiteStatus, getUserInfo, login, logout} from "../../network/userRequest";
import {loginRegisterMixin} from "../../common/mixin";
import sportsConst from "../../common/sportsConst";
import {RECEIVE_HIDE_LOADING, RECEIVE_SHOW_LOADING, RECEIVE_USER_INFO} from "../../store/mutation-types";
import TopbarComp from "../../components/TopbarComp";
import LeftBarComp from "../../components/LeftBarComp";
import RightBarComp from "../../components/RightBarComp";
import FootComp from "../../components/FootComp";
import RightBarBannerComp from "../../components/RightBarBannerComp";
import SportsLeftBarComp from "../../components/sports/SportsLeftBarComp";
import WarningSample from "../../components/WarningSample";

export default {
  name: "login",
  components: {
    WarningSample,
    SportsLeftBarComp, RightBarBannerComp, FootComp, RightBarComp, LeftBarComp, TopbarComp
  },
  mixins: [loginRegisterMixin],
  data() {
    return {
      user: {
        username: '',
        passwd: '',
        hvcode: null,
      },
      isShowMessage: false,
      content: '',
      errmessage: null,
      popup1: null,
      popup2: null,
      imageSrc: null,
    }
  },
  methods: {
    doLogin() {

      this.$store.commit(RECEIVE_SHOW_LOADING);
      login(this.user).then(res => {
        if (res.data.success) {
          this.errmessage = null
          getUserInfo().then(res => {
            this.$store.commit(RECEIVE_HIDE_LOADING);
            this.$store.state.isLogin = true;
            this.$store.state.userInfo = res.data.data
            //this.$store.commit(RECEIVE_USER_INFO, {userInfo});
            //window.sessionStorage.setItem("isLogin", 'true');
            this.$router.push('/main').catch(error => {
              console.info(error.message)
            });
          }, err => {
            console.log(err)
          })
        } else {
          this.$store.commit(RECEIVE_HIDE_LOADING);
          // this.$swal({
          //     title: res.data.msg,
          //     type: 'error',
          //     showCancelButton: false,
          //     showConfirmButton: true
          // })
          this.errmessage = res.data.msg;
        }
      })
    },
    donotlogin() {

    },
    close24(item) {
      this.$set(item, 'isShow', false)
      this.$cookies.set('pop' + item.id, '1', 60 * 60 * 12, '/')
    },
    close(item) {
      //this.$store.state.popups.push(item.id)
      this.$set(item, 'isShow', false)
    },
    getPopList() {
      getPopupList(sportsConst.POPUP_PLATFORM_LOGIN).then(res => {
        if (res.data.success) {
          this.plist = res.data.data
          this.plist.forEach(popup => {
            let cookiePopupStatus = this.$cookies.get('pop' + popup.id)
            this.$set(popup, 'isShow', cookiePopupStatus == null)

          })
          if (this.plist.length == 1) {
            this.popup1 = this.plist[0];
          }
          if (this.plist.length == 2) {
            this.popup1 = this.plist[0];
            this.popup2 = this.plist[1];
          }
        }
      })
    },
    getHVCode() {
      switch (process.env.NODE_ENV) {
        case 'development':
          let prot = window.location.protocol
          let domain = window.location.host.replaceAll("8080", '7070')
          this.imageSrc = prot + '//' + domain + '/api/user/hvcode';
          break
        case 'production':
          let prot2 = window.location.protocol
          let domain2 = window.location.host
          this.imageSrc = prot2 + '//' + domain2 + '/api/user/hvcode';
          break
      }

    },
    refreshHVcode() {
      window.location.reload()
    },
    showNotMemberCustomerPanel() {

      if (!this.$store.state.isLogin) {
        this.$store.state.isShowRegisterPanel = false
        this.$store.state.isShowLoginPanel = false
        this.$store.state.isShowNotMemberCustomerePanel = true
      }
    },
    showRegisterPanel() {
      if (!this.$store.state.isLogin) {
        this.$store.state.isShowRegisterPanel = true
        this.$store.state.isShowLoginPanel = false
      }
    },

  },
  created() {
    this.getHVCode()
    getSiteStatus().then(res => {
      if (!res.data.success) {
        this.$store.state.isMaintaining = true;
        this.$store.state.maintainingMessage = res.data.msg
        this.$store.state.isLogin = false;
        this.$store.state.isShowLoginPanel = false;
      } else {
        this.$store.state.isMaintaining = false;
        this.$store.state.maintainingMessage = ''
      }
    })
    //this.getPopList()
  },
  computed: {
    allowUsername() {
      return /^[a-z][0-9a-z]{3,20}$/.test(this.username)
    },
    allowPasswd() {
      return /^.{3,30}$/.test(this.passwd)
    }
  }
}
</script>

<style scoped>
@import url("../../assets/css/base.css");

body {
  background-color: #f9f0df !important;
}

.login_content {
  width: 100%;
  height: 100vh;
  background: #f9f0df url("../../assets/images/login/ptn/login-bg.jpg") no-repeat center center;
}

.crt_panel {
  width: 265px;
  position: absolute;
  left: 50%;
  top: 30px;
  -webkit-transform: translateX(-50%) scale(1);
  transform: translateX(-50%) scale(1);
  z-index: 9;
  text-align: center;
}

.login_panel {
  width: 400px;
  height: 540px;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%) scale(1);
  transform: translateX(-50%) translateY(-50%) scale(1);
  z-index: 10;
  border-radius: 10px;
  background: rgba(87, 193, 219, .8);
  border: 0px solid #dbf0f3;
  border-image: linear-gradient(to right, #dbf0f3, #dbf0f3) 1;
  box-shadow: 0 0 10px #dbf0f3;
  padding: 20px;
  box-sizing: border-box;
}


.login_panel .login_logo {
  width: 100%;
  text-align: center;
  padding: 10px 0;
}


.login_items {
  width: 100%;
  height: 260px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 0;
  margin-top: 80px;

}

.ipt {
  width: 80%;
  height: 50px;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px 0;
}

.ipt input {
  width: 100%;
  height: 48px;
  border-radius: 1px;
  border: 1px solid #0fb1c8;
  background-color: #0fb1c8;
  --box-shadow: inset 2px 3px 13px rgba(206, 248, 60, 0.5);
  text-align: center;
  color: #fff9ff;

}

.btns {
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
  flex-wrap: wrap;
}


.btns button {
  width: 100%;
  height: 50px;
  line-height: 50px;
  font-size: 18px;
  font-weight: bold;
  border-radius: 2px;
  margin-bottom: 10px;

}

.btns .login {
  background: linear-gradient(180deg, #2690aa, #2690aa);
  color: #feffff;
  border: 1px solid #0cc7e7;
  border-radius: 3px;
}

.btns .register {
  background: linear-gradient(180deg, #1d70b6, #1d70b6);
  color: #fff9ff;
}

.btns .nomemberask {
  width: 100%;
  background: linear-gradient(180deg, #585858, #585858);
  color: #feffff;
  border: 1px solid #616161;
  border-radius: 3px;
  height: 50px;
  line-height: 50px;
  font-size: 12px;
}

.telorkakao {
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}

.telorkakao img {
  width: 83%;
}

.fadeIn {
  opacity: 0;
  -webkit-animation: fadeIn ease-in 1;
  -moz-animation: fadeIn ease-in 1;
  animation: fadeIn ease-in 1;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  animation-duration: 1s;
}

.fadeIn.fourth {
  animation-delay: 0.3s;
  -webkit-animation-name: fourth1;
  animation-name: fourth1;
}

@media screen and (max-width: 1024px) {
  .login_content {
    background: #fbf2e3 url("../../assets/images/login/ptn/login-bg-mobile.png") no-repeat top;
    background-size: cover;
  }

  .login_panel {
    width: 90%;
    height: 540px !important;
    -webkit-transform: translateX(-50%) translateY(-55%) scale(1) !important;
    transform: translateX(-50%) translateY(-55%) scale(1) !important;
  }

  .login_items {
    display: flex !important;
  }

  .blo {
    display: none !important;
  }
}

.err {
  color: #ff4d4d;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  animation: sansan 700ms infinite;
  -webkit-animation: sansan 700ms infinite;
}

.sampletext {
  animation: sansan 700ms infinite;
  -webkit-animation: sansan 700ms infinite;
}


@keyframes fourth1 {
  0% {
    opacity: 0;

  }
  100% {
    opacity: 1;
  }
}

@keyframes sansan {
  from {
    opacity: 1.0;
  }
  70% {
    opacity: 0.8;
  }
  50% {
    opacity: 0.6;
  }
  to {
    opacity: 1.0;
  }
}

@-webkit-keyframes sansan {
  from {
    opacity: 1.0;
  }
  50% {
    opacity: 0.4;
  }
  to {
    opacity: 1.0;
  }
}

.p1 {
  position: absolute;
  top: 30%;
  left: 10%;
  z-index: 100002;
}

.p2 {
  position: absolute;
  top: 30%;
  right: 10%;
  z-index: 100001;
}

.popup_section {
  background-color: #1e1e1e;
  color: #fff9ff;
  margin: 5px;
  width: 375px;
  --border-image: linear-gradient(to right, #ffd680, #ffd680) 1;
  --box-shadow: 0 0 15px #fee394;
}

.popup_section h4 {
  line-height: 20px;
  height: 20px;
  width: 100%;
  font-size: 12px;
  padding: 20px;
  color: #cca4fd;
}

.popup_section .btn-close {
  padding: 8px 8px;
  background-color: #f1ae17;
  color: #0c0e0e;
}

.pop_box {
  box-sizing: border-box;
}

.pop_box h2 span:hover {
  color: #f19f09;
}

.popup_section .pfoot {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  line-height: 40px
}

.pfoot div {
  width: 45%;
  font-size: 15px;
  font-weight: bold;
  padding: 0 5px;
}

.samplerver {
  width: 100% !important;
}

@media screen and (max-width: 2560px) {
  .popup {
    -webkit-transform: translateX(-50%) translateY(-50%) scale(1);
    transform: translateX(-50%) translateY(-50%) scale(1);

  }
}

@media screen and (max-width: 1920px) {
  .popup {
    -webkit-transform: translateX(-50%) translateY(-37%) scale(1);
    transform: translateX(-50%) translateY(-37%) scale(1);

  }
}

@media screen and (max-width: 1024px) {

  .p1 {
    top: 10% !important;
    left: 0% !important;
  }

  .p2 {
    top: 10% !important;
    left: 0% !important;
  }

  .popup_section {
    width: 100% !important;
  }
}
</style>
